import { call, put, takeEvery } from "redux-saga/effects";
import { actions } from "./index";
import { REACT_APP_COP_SERVER } from "../../../utils/env";
import getCroppedImg from "../../../utils/cropImage";

// Helper to create an API request
const apiRequest = (url, method, body = null) => {
  return fetch(url, {
    method,
    headers: {
      "Content-Type": "application/json",
    },
    body: body ? JSON.stringify(body) : null,
  }).then((response) => {
    if (!response.ok) {
      throw new Error(`HTTP error! Status: ${response.status}`);
    }
    return response.json();
  });
};

// Create Project Saga
function* createProject(action) {
  const { project } = action.payload;
  if (project.fileCrop && project.preview) {
    try {
      const croppedImage = yield call(
        getCroppedImg,
        project.preview,
        project.fileCrop.image,
        project.fileCrop.rotation
      );
      project.cropPreview = croppedImage;
    } catch (e) {
      console.log("AN ERROR OCCURRED WHILE CREATING PREVIEW", e);
      console.error(e);
    }
  }
  try {
    const payload = yield call(
      apiRequest,
      `${REACT_APP_COP_SERVER}/api/v1/projects.json`,
      "POST",
      action.payload.project
    );
    yield put(actions.createSuccess(payload));
    if (action.payload.successCallback) {
      action.payload.successCallback(payload);
    }
  } catch (error) {
    yield put(actions.createFail(error.message));
  }
}

// Update Project Saga
function* updateProject(action) {
  try {
    const payload = yield call(
      apiRequest,
      `${REACT_APP_COP_SERVER}/api/v1/projects/${action.payload.id}`,
      "PUT",
      action.payload.data
    );
    yield put(actions.updateSuccess(payload));
  } catch (error) {
    yield put(actions.updateFail(error.message));
  }
}

// List Projects Saga
function* listProjects(action) {
  try {
    const payload = yield call(
      apiRequest,
      `${REACT_APP_COP_SERVER}/api/v1/projects`,
      "GET"
    );
    yield put(actions.listSuccess(payload));
  } catch (error) {
    yield put(actions.listFail(error.message));
  }
}

// Show Project Saga
function* showProject(action) {
  try {
    const payload = yield call(
      apiRequest,
      `${REACT_APP_COP_SERVER}/api/v1/projects/${action.payload.id}`,
      "GET"
    );
    yield put(actions.showSuccess(payload));
  } catch (error) {
    yield put(actions.showFail(error.message));
  }
}

// Watcher Saga
function* projectsSaga() {
  yield takeEvery(actions.create, createProject);
  yield takeEvery(actions.update, updateProject);
  yield takeEvery(actions.list, listProjects);
  yield takeEvery(actions.show, showProject);
}

export default projectsSaga;
