import { createSlice, createSelector } from '@reduxjs/toolkit';

export const projectsSlice = createSlice({
  name: 'projects',
  initialState: {
    show: null,
    list: [],
    loadingShow: false,
    loadedShow: false,
    showError: null,
    creating: false,
    created: false,
    createError: null,
    updating: false,
    updated: false,
    updateError: null,
    removing: false,
    removed: false,
    removeError: null,
    idsList: [],
    dictionary: {},
    slugIndex: {},
    showId: null,
    filtersToIdsDictionary: {},
  },
  reducers: {
    list: (state) => {
      state.loadingList = true;
      state.loadedList = false;
      state.listError = null;
      state.list = [];
    },
    listSuccess: (state, action) => {
      const responseList = action.payload.data || [];
      const responseIds = responseList.map((project) => project.id);

      state.loadingList = false;
      state.loadedList = true;
      state.list = responseList;
      state.idsList = responseIds;
      responseList.forEach((entry) => {
        state.dictionary[entry.id] = entry;
      });
    },
    listFail: (state, action) => {
      state.loadingList = false;
      state.loadedList = false;
      state.listError = action.payload.mess;
    },
    show: (state, action) => {
      state.loadingShow = true;
      state.loadedShow = false;
      state.showError = null;
      state.show = state.dictionary[action.payload.id] || {};
      state.showId = action.payload.id || null;
    },
    resetProject: (state, action) => {
      state.dictionary[action.payload.id] = {};
      state.showId = null;
      state.creating = false;
      state.updating = false;
    },
    showSuccess: (state, action) => {
      const project = action.payload.data;
      state.loadingShow = false;
      state.loadedShow = true;
      state.show = project;
      state.showId = project.id;
      state.dictionary[project.id] = project;
    },
    showFail: (state, action) => {
      state.loadingShow = false;
      state.loadedShow = false;
      state.showError = action.payload.mess;
    },
    update: (state) => {
      state.updating = true;
      state.updated = false;
      state.updateError = null;
    },
    updateSuccess: (state, action) => {
      const project = action.payload.data;
      state.updating = false;
      state.updated = true;
      state.show = project;
      state.showId = project.id;
      state.dictionary[project.id] = project;
    },
    updateFail: (state, action) => {
      state.updating = false;
      state.updated = false;
      state.updateError = action.payload.response.obj;
    },
    create: (state) => {
      state.creating = true;
      state.created = false;
      state.createError = null;
    },
    createSuccess: (state, action) => {
      const project = action.payload.data;
      state.creating = false;
      state.created = true;
      state.show = project;
      state.showId = project.id;
      state.dictionary[project.id] = project;
    },
    createFail: (state, action) => {
      state.creating = false;
      state.created = false;
      state.createError = action.payload;
    },
    resetCreateFail: (state, action) => {
      state.createError = null;
    },
    updateDictionary: (state, action) => {
      action.payload.forEach((project) => {
        state.dictionary[project.id] = project;
      });
    },
    addToDictionary: (state, action) => {
      state.dictionary[action.payload.id] = {
        ...state.dictionary[action.payload.id],
        ...action.payload,
      };
    },
  },
});

// Export actions
export const {
  list: listProjects,
  show: showProject,
  update: updateProject,
  create: createProject,
  resetProject,
  resetCreateFail,
  updateDictionary: updateProjectsDictionary,
  addToDictionary: addToProjectsDictionary,
} = projectsSlice.actions;

export const actions = projectsSlice.actions;

// Selectors
export const projectsList = (state) =>
  state.projects.idsList.map((id) => state.projects.dictionary[id]);

export const projectShowId = (state) => state.projects.showId;

export const getProject = createSelector(
  (state) => state.projects.dictionary,
  (_, id) => id,
  (dictionary, id) => dictionary[id]
);

export const getProjectAttributes = createSelector(
  (state) => state.projects.dictionary,
  (_, id) => id,
  (dictionary, id) => dictionary[id]?.attributes
);

export const getProjectRelationships = createSelector(
  (state) => state.projects.dictionary,
  (_, id) => id,
  (dictionary, id) => dictionary[id]?.relationships
);

export default projectsSlice.reducer;
